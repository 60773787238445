<template>
  <v-expansion-panel
    flat
    class="mb-2 xborder-1 xrounded-lg xbg-[#fff] bot-type-each"
  >
    <v-expansion-panel-header class="xfont-bold xtext-[18px] xtext-[#353535]">
      <div v-html="title"></div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="xp-0">
      <v-hover
        v-for="bots in responses"
        :key="bots.id"
        v-slot:default="{ hover }"
      >
        <div
          :class="[hover ? 'xborder-[#d1d1d1]' : 'xborder-[#ece5e5]']"
          class="xw-full xmy-2 xborder xbg-white xrounded-md xp-2 xpx-[10px] xflex xflex-row xjustify-between xitems-center"
        >
          <div
            class="xflex xflex-row xflex-1 xjustify-start xgap-2 xitems-center"
          >
            <v-avatar class="xp-1">
              <v-img contain :src="require('@/assets/logo-square.png')" />
            </v-avatar>
            <div class="xflex xflex-col xw-full xjustify-start xitems-start">
              <span class="xfont-bold">{{ appName }}</span>
              <div class="xtext-gray-600 xw-full xflex-1">
                {{ bots.message }}
              </div>
            </div>
          </div>
          <div class="xflex xflex-row xjustify-end xitems-center xgap-2">
            <icon-tooltip
              @click="editResponse(bots)"
              clickable
              v-if="$can.and('manage_bot_responses')"
              tip="Edit this response"
              icon="mdi-comment-edit-outline"
            ></icon-tooltip>
            <icon-tooltip
              @click="deleteResponse(bots)"
              clickable
              v-if="$can.and('manage_bot_responses')"
              tip="Delete this response"
              icon="mdi-trash-can-outline"
            ></icon-tooltip>
          </div>
        </div>
      </v-hover>
      <!-- <app-empty v-if="!responses.length"></app-empty> -->
      <InputEmoji
        class="md:xw-10/12 xw-full"
        v-model="bot"
        :placeholder="
          !$can.and('manage_bot_responses')
            ? 'Not enough permission! Cannot add bot response'
            : `Enter ${type} response message.`
        "
        :disabled="!$can.and('manage_bot_responses')"
      >
        <btn-tooltip
          @click="saveBot"
          tip="Save new bot response"
          v-if="!isEdit"
          :loading="submitting"
          :disabled="!valid || !$can.and('manage_bot_responses')"
          color="primary"
        >
          <v-icon left>mdi-plus</v-icon> Save
        </btn-tooltip>
        <btn-tooltip
          @click="editBot"
          :disabled="!valid || !$can.and('manage_bot_responses')"
          tip="Update bot response"
          v-else
          :loading="submitting"
          color="primary"
        >
          <v-icon left>mdi-content-save</v-icon> Update
        </btn-tooltip>
        <btn-tooltip
          @click="cancelEdit"
          tip="Cancel updating"
          v-if="isEdit && $can.and('manage_bot_responses')"
          color="base-100"
        >
          <v-icon left>mdi-cancel</v-icon> Cancel
        </btn-tooltip>
      </InputEmoji>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import InputEmoji from "@/views/GlobalComponents/Forms/InputEmoji.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { InputEmoji },
  props: {
    title: { type: String, default: "" },
    type: { type: String, required: true }, //in,brb,back,out
  },
  data() {
    return {
      isEdit: false,
      activeBot: null,
      bot: null,
      submitting: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("bots", ["in_bots", "brb_bots", "back_bots", "out_bots"]),
    responses() {
      return this[`${this.type}_bots`];
    },
    valid() {
      return this.bot;
    },
    payload() {
      return {
        id: this.isEdit ? this.activeBot.id : null,
        type: this.type,
        message: this.bot,
      };
    },
  },
  methods: {
    ...mapActions("bots", ["saveNewBot", "updateBot", "deleteBot"]),
    editResponse(bot) {
      this.isEdit = true;
      this.activeBot = bot;
      this.bot = bot.message;
    },
    deleteResponse(bot) {
      this.appConfirmation("Delete this bot response?", () => {
        this.deleteBot({
          id: this.user.team_id,
          payload: bot,
          cb: () => {
            this.appToast("Successfully deleted", "success");
          },
        });
      });
    },
    saveBot() {
      if (this.valid) {
        this.submitting = true;
        this.saveNewBot({
          id: this.user.team_id,
          payload: this.payload,
          cb: () => {
            this.appToast("New bot response saved", "success");
            this.cancelEdit();
            this.submitting = false;
          },
        });
      }
    },
    editBot() {
      if (this.valid) {
        this.submitting = true;
        this.updateBot({
          id: this.user.team_id,
          payload: this.payload,
          cb: () => {
            this.appToast("Bot response updated", "success");
            this.cancelEdit();
            this.submitting = false;
          },
        });
      }
    },
    cancelEdit() {
      this.activeBot = null;
      this.isEdit = false;
      this.bot = null;
    },
  },
};
</script>

<style lang="css">
.bot-type-each .clock-in {
  color: #1ab800;
}
.bot-type-each .clock-brb {
  color: #5451ff;
}
.bot-type-each .clock-back {
  color: #e98c00;
}
.bot-type-each .clock-out {
  color: #ef0000;
}
</style>
